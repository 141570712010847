import { Component } from '@angular/core';
import { WebsocketService } from './Services/websocket.service';
import { DisplayLoadingService } from './Services/display-loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  //title = 'bootDash';
  title = 'QLess';
  content = '';
  received = [];
  sent = [];

  constructor(
    public dl : DisplayLoadingService,
    websocket_service: WebsocketService,
  ){}
}
