import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpEventType
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay, finalize, map } from 'rxjs/operators';
import { DisplayLoadingService } from '../Services/display-loading.service';

@Injectable()
export class LoadspinnerInterceptor implements HttpInterceptor {

  private count = 0;

  constructor(
    private loadingservice : DisplayLoadingService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler){
    
    if (request.headers.get("skip")){

      request = request.clone({
        headers: request.headers.delete('skip'),
      });
      return next.handle(request);
    }

    const authToken = localStorage.getItem("token");
    const authReq = request.clone({ 
        headers: request.headers.set('Authorization', "Token "+authToken) ,
        reportProgress : true
    });

    return next.handle(authReq).pipe(
      delay(0),
      map((event : HttpEvent<any>) =>{
    
            switch(event.type){
              case HttpEventType.Sent:
                //console.warn("Request launched");
                this.loadingservice.show();
                break;
              case HttpEventType.DownloadProgress:
                //console.warn("Data are downloading still at "+ event.loaded+" %" )
                break
              case HttpEventType.Response:
                //console.warn("Data are completely downloaded");
                this.loadingservice.hide();
                break
            }

            return event
      })
    )

    return next.handle(authReq)
  }
}
