import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { Agent2, User, User2 } from 'src/app/models/modeles';
import { UserService } from 'src/app/Services/user.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { isEmpty } from 'rxjs/operators';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {

    notifications: any[];
    errorMessage;
    loading: boolean;
    currentUser:Agent2 =  new Agent2();
    isLoaded = false;
    nom="";
    nom_abrege="";
    service="";
    poste="";

    constructor(
      private navService: NavigationService,
      public searchService: SearchService,
      private auth: AuthService,
      private user_service:UserService,
      private auth_service:AuthenticationService,
      private router:Router,
      private toastr: ToastrService,
    ) {
      this.notifications = [
        {
          icon: 'i-Speach-Bubble-6',
          title: 'New message',
          badge: '3',
          text: 'James: Hey! are you busy?',
          time: new Date(),
          status: 'primary',
          link: '/chat'
        },
        {
          icon: 'i-Receipt-3',
          title: 'New order received',
          badge: '$4036',
          text: '1 Headphone, 3 iPhone x',
          time: new Date('11/11/2018'),
          status: 'success',
          link: '/tables/full'
        },
        {
          icon: 'i-Empty-Box',
          title: 'Product out of stock',
          text: 'Headphone E67, R98, XL90, Q77',
          time: new Date('11/10/2018'),
          status: 'danger',
          link: '/tables/list'
        },
        {
          icon: 'i-Data-Power',
          title: 'Server up!',
          text: 'Server rebooted successfully',
          time: new Date('11/08/2018'),
          status: 'success',
          link: '/dashboard/v2'
        },
        {
          icon: 'i-Data-Block',
          title: 'Server down!',
          badge: 'Resolved',
          text: 'Region 1: Server crashed!',
          time: new Date('11/06/2018'),
          status: 'danger',
          link: '/dashboard/v3'
        }
      ];

    }
  
    ngOnInit() {
      //get the current user
      this.getCurrentUser();
    }
  
    toggelSidebar() {
      const state = this.navService.sidebarState;
      if (state.childnavOpen && state.sidenavOpen) {
        return state.childnavOpen = false;
      }
      if (!state.childnavOpen && state.sidenavOpen) {
        return state.sidenavOpen = false;
      }
      // item has child items
      if (!state.sidenavOpen && !state.childnavOpen 
        && this.navService.selectedItem.type === 'dropDown') {
          state.sidenavOpen = true;
          setTimeout(() => {
              state.childnavOpen = true;
          }, 50);
      }
      // item has no child items
      if (!state.sidenavOpen && !state.childnavOpen) {
        state.sidenavOpen = true;
      }
    }
  
    signout() {
      //localStorage.setItem('token' , null);
      this.currentUser = null;
      

      this.auth_service.logout().subscribe({
        next:data=>{

          /** delete localstorage */
          localStorage.removeItem('currentUser');
          localStorage.removeItem('token');

          /** Redirect */
          this.router.navigateByUrl('/admin/sessions/signout');
        },
        error:error=>{
          console.error(error)
        }
      });
      
    }

    getCurrentUser(){
        var token;

        this.user_service.currentUser.subscribe(m=>{
          //console.warn(m.user)
          
          if( m?.user == undefined){


            token = localStorage.getItem('token');

            if(token != null){

              this.auth_service.loginWToken(token).subscribe({
                next: (data: any) => {
                  if (data != null) {
                    this.currentUser = data;
                    this.isLoaded = true;
                    this.nom = this.currentUser !== null ? this.currentUser.user?.first_name + " " + this.currentUser.user?.last_name : "";
                    this.nom_abrege = this.currentUser !== null ? this.currentUser.user?.first_name[0] + "" + this.currentUser.user?.last_name[0] : "";
                    this.service = this.currentUser?.company?.company_name === null ? "" : this.currentUser?.company?.company_name;
                    this.poste = this.currentUser?.poste === null ? "Administrateur" : this.currentUser?.poste;

                  } else {
                    this.router.navigateByUrl('/admin/sessions/signin');
                  }
                },
                error: e => {
                  this.errorMessage = e.message;
                  let error = e.error;
                  if(e.statusText == "Unauthorized"){
                    this.router.navigateByUrl('/admin/sessions/signin'); //rediriger vers la page de connexion si token invalid
                  }
                  else{
                    console.error("There is an error !", e.error)
                    setTimeout(() => {
                        this.loading = false;
                        this.toastr.error('There is an error.', 'Error!', { progressBar: true });
                    }, 3000);
                  }
                }
              })
            }
            else{
              this.router.navigateByUrl('/session/signin')
            }
            
          }
          else{
            this.currentUser = m;
            this.isLoaded = true
            this.nom = this.currentUser !== null ? this.currentUser.user?.first_name + " " + this.currentUser?.user?.last_name : "";
            this.nom_abrege = this.currentUser !== null ? this.currentUser.user?.first_name[0] + "" + this.currentUser.user?.last_name[0] : "";
            this.service = this.currentUser?.company?.company_name === null ? "":this.currentUser?.company?.company_name;
            this.poste = this.currentUser?.poste === null ? "Administrateur": this.currentUser?.poste;
          }

        })
    }
}
