import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Departement, Guichet, Reception, Role, Service, User } from '../models/modeles';

@Injectable({
  providedIn: 'root'
})
export class DepartServiceService {

  insert_depart_url = environment.apidomain+"departement/add-depart";
  get_All_depart_url = environment.apidomain+"departement/get-all-depart";
  get_All_depart_by_company_url = environment.apidomain+"departement/get-company-depart";
  get_All_depart_by_company_depart_url = environment.apidomain+"departement/get-company-depart_by_ID";

  
  insert_service_url = environment.apidomain+"service/add-service";
  get_All_service_url = environment.apidomain+"service/get-all-service";

  insert_role_url = environment.apidomain+"service/add-role";
  get_All_role_url = environment.apidomain+"service/get-company-role";

  insert_operation_by_company_url = environment.apidomain+"service/add-operation";
  get_All_operation_by_company_url = environment.apidomain+"service/get-company-operation";
  get_All_operation_by_company_operation_url = environment.apidomain+"service/get-company-operation-by-ID";

  get_All_privilege_url = environment.apidomain+"service/get-all-privilege";

  insert_guichet_by_company_url = environment.apidomain+"service/add-guichet";
  get_All_guichet_by_company_url = environment.apidomain+"service/get-company-guichet";
  get_All_guichet_by_company_guichet_url = environment.apidomain+"service/get-company-guichet-by-ID";

  get_All_service_by_company_url = environment.apidomain+"service/get-company-service";
  get_All_service_by_company_service_url = environment.apidomain+"service/get-company-service-by-ID";
  get_All_role_by_company_url = environment.apidomain+"service/get-company-role";
  get_All_role_by_company_role_url = environment.apidomain+"service/get-company-role-by-ID";

  get_All_typeRecep_by_company_url = environment.apidomain+"service/get-all-typeRecep-by-company";
  insert_typeRecep_url = environment.apidomain+"service/add-typeRecep";

  search_reception_url = environment.phpapidomain+"search_reception";

  get_All_performance_by_company_url = environment.phpapidomain+"list_performance";


  /** OPACITY API */
  insert_role_url_2 = environment.pythonapidomain+"roles/";
  get_All_role_by_company_url_2 = environment.pythonapidomain+"roles/";

  constructor(private http:HttpClient) { }

  insertDepartement<Departement>(departement: Departement){
    return this.http.post(this.insert_depart_url, departement)
  }

  insertService<Service>(service: Service){
    return this.http.post(this.insert_service_url, service)
  }

  insertRole<Role>(role: Role){
    return this.http.post(this.insert_role_url_2, role)
  }

  updateRole(role: any){
    return this.http.put(this.insert_role_url_2+role.id+"/", role)
  }

  insertOperation<Operation>(role: Operation){
    return this.http.post(this.insert_operation_by_company_url, role)
  }

  insertGuichet<Guichet>(role: Guichet){
    return this.http.post(this.insert_guichet_by_company_url, role)
  }

  insertTypeReception<TypeReception>(typeReception: TypeReception){
    return this.http.post(this. insert_typeRecep_url, typeReception)
  }

  getAllDepartement():Observable<any>{
    return this.http.get(this.get_All_depart_url)
  }

  getAllService():Observable<any>{
    return this.http.get(this.get_All_service_url)
  }

  getAllPrivilege():Observable<any>{
    return this.http.get(this.get_All_privilege_url)
  }

  getAllDepartByCompany(idcompany):Observable<any>{
    return this.http.get(this.get_All_depart_by_company_url,{
      params:{
        "idcompany":idcompany
      }
    })
  }

  getAllDepartByCompanyAndId(idcompany,idDepart):Observable<any>{
    return this.http.get(this.get_All_depart_by_company_depart_url,{
      params:{
        "idcompany":idcompany,
        "idDepart":idDepart
      }
    })
  }

  getAllServiceByCompany(idcompany):Observable<any>{
    return this.http.get(this.get_All_service_by_company_url,{
      params:{
        "idcompany":idcompany
      }
    })
  }

  getAllServiceByCompanyAndIdService(idcompany,idService):Observable<any>{
    return this.http.get(this.get_All_service_by_company_service_url,{
      params:{
        "idcompany":idcompany,
        "idService":idService
      }
    })
  }

  getAllRoleByCompany(idcompany):Observable<any>{
    return this.http.get(this.get_All_role_by_company_url_2,{
      params:{
        "company":idcompany
      }
    })
  }
  
  getAllRoleByCompanyById(idcompany,idRole):Observable<any>{
    return this.http.get(this.get_All_role_by_company_url_2+idRole)
  }

  getAllOperationByCompany(idcompany):Observable<any>{
    return this.http.get(this.get_All_operation_by_company_url,{
      params:{
        "idcompany":idcompany
      }
    })
  }

  getAllOperationByCompanyAndIdOperation(idcompany,idOperation):Observable<any>{
    return this.http.get(this.get_All_operation_by_company_operation_url,{
      params:{
        "idcompany":idcompany,
        "idOperation":idOperation
      }
    })
  }

  getAllGuichetByCompany(idcompany):Observable<any>{
    return this.http.get(this.get_All_guichet_by_company_url,{
      params:{
        "idcompany":idcompany
      }
    })
  }

  getAllTypeReceptionByCompany(idcompany):Observable<any>{
    return this.http.get(this.get_All_typeRecep_by_company_url,{
      params:{
        "idcompany":idcompany
      }
    })
  }

  getAllGuichetByCompanyIdGuichet(idcompany,idGuichet):Observable<any>{
    return this.http.get(this.get_All_guichet_by_company_guichet_url,{
      params:{
        "idcompany":idcompany,
        "idGuichet":idGuichet
      }
    })
  }

  getAllOperationByServiceAndCompany(idcompany,idService):Observable<any>{
    return this.http.get(this.get_All_operation_by_company_url,{
      params:{
        "idcompany":idcompany,
        "idservice":idService
      }
    })
  }

  searchReception(inputs:any, idcompany:any):Observable<Reception[]>{ //recherche dans la table reception globalement
    return this.http.post<Reception[]>(this.search_reception_url, inputs ,{
      params:{
        "idcompany":idcompany
      }
    })
  }

  getAllAgentPerformanceByCompany(idcompany):Observable<any>{
    return this.http.get(this.get_All_performance_by_company_url,{
      params:{
        "company_id":idcompany,
      }
    })
  }

  searchPerformance(inputs:any):Observable<any[]>{ //Recherche les performances des agents
    return this.http.post<any[]>(this.get_All_performance_by_company_url, inputs)
  }
}
