import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Agent, Agent2, Departement, Operation, Reception, Service, TypeReception, User } from 'src/app/models/modeles';
import { AgentService } from 'src/app/Services/agent.service';
import { AuthenticationService } from 'src/app/Services/authentication.service';
import { DepartServiceService } from 'src/app/Services/depart-service.service';
import { ReceptionService } from 'src/app/Services/reception.service';
import { TokenService } from 'src/app/Services/token.service';
import { UserService } from 'src/app/Services/user.service';
import { WebsocketService } from 'src/app/Services/websocket.service';
import { HeaderSidebarLargeComponent } from 'src/app/shared/components/layouts/admin-layout-sidebar-large/header-sidebar-large/header-sidebar-large.component';
import Swal from 'sweetalert2';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-ajouter-entree',
  templateUrl: './ajouter-entree.component.html',
  styleUrls: ['./ajouter-entree.component.scss']
})
export class AjouterEntreeComponent implements OnInit {

  formBasic: FormGroup;
  loading: boolean;
  radioGroup: FormGroup;
  departement = new Departement();
  reception = new Reception();
  errorMessage;
  arrayService:Service[]=[]
  currentUser:Agent2;
  arrayReception:Reception[]=[];
  arrayReceptionByOp:Reception[]=[];
  nombreItem;
  arrayOperation:Operation[]=[];
  arrayOperationDispalyed:Operation[]=[];
  selectedServiceId;
  isTypeRecepVisit:boolean=true;
  isAgentDisplay:boolean=false;
  arrayAgent:Agent[] = [];
  filteredOptions : Observable<Agent[]>;
  myControl = new FormControl('');
  arrayTypeReception:TypeReception[]=[];
  agentVisite = new Agent();
  


  constructor(
    private departService: DepartServiceService,
    private agentService: AgentService,
    private receptionService:ReceptionService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private user_service:UserService,
    private token_service:TokenService,
    private router:Router,
    private auth_service:AuthenticationService,
    private ws_service:WebsocketService
  ) { }

  ngOnInit(): void {
    //this.getAllService();
    this.getCurrentUser();

    this.filteredOptions = this.myControl.valueChanges.pipe(
      //startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.arrayAgent.slice();
      }),
    );

  }

  private _filter(name: string): Agent[] {
    const filterValue = name.toLowerCase();
    return this.arrayAgent.filter(
      (option) =>  {
        //this.agentVisite=(option.nom+" "+option.prenom).toLowerCase().includes(filterValue)?option:null;
        return (option.nom+" "+option.prenom).toLowerCase().includes(filterValue);
      }
    );
  }

  selectAgent(agent){
    this.reception.agentVisite = agent;
    console.warn(JSON.stringify(agent));
  }
 

  getAllService(){
    this.departService.getAllServiceByCompany(this.currentUser.company.id).subscribe({
      next:data =>{
        this.arrayService = data
        //console.log("les services sont:"+ JSON.stringify(this.arrayService));
      },
      error:error =>{
        console.error("There is an error !", error)
      }
    })
  }

  getAllOperation(){
    this.departService.getAllOperationByCompany(this.currentUser.company.id).subscribe({
      next:data =>{
        this.arrayOperation = data;
        //console.log(JSON.stringify(data));
        //console.log("les departements sont:"+ JSON.stringify(this.arrayService));
      },
      error:error =>{
        console.error("There is an error !", error)
      }
    })
  }

  getAllTypeReception(){
    this.departService.getAllTypeReceptionByCompany(this.currentUser.company.id).subscribe({
      next:data =>{
        this.arrayTypeReception = data;
        //console.log(JSON.stringify(data));
        //console.log("les departements sont:"+ JSON.stringify(this.arrayService));
      },
      error:error =>{
        console.error("There is an error !", error)
      }
    })
  }

  CountReceptionByOperation(operation:Operation){
    this.getAllReception();
    if(this.arrayReception.length > 0  && !this.isTypeRecepVisit){
      this.arrayReceptionByOp = this.arrayReception.filter((item)=>{
        console.warn(JSON.stringify(operation));
        return item?.operation?.id === operation?.id;
      })
    }
    else{
      this.arrayReceptionByOp = [];
    }
    return this.arrayReceptionByOp.length;  
  }

  getOperationByService(service_libelle:any){
    this.arrayOperationDispalyed = this.arrayOperation;
    console.log(service_libelle?.target?.value);
    //this.currentServiceLibelle = service_libelle?.target?.value;

    this.arrayOperationDispalyed = this.arrayOperation.filter((item)=>{
         console.log(JSON.stringify(item.service.id));
         return item.service.id == service_libelle?.target?.value;
     });
 
     console.log(`is operation array empty ${this.arrayOperationDispalyed.length}`);
  }

  getCurrentUser(){
    var token;
    this.user_service.currentUser.subscribe(data=>{
      
      if(this.currentUser == undefined){
        token = localStorage.getItem('token');
        this.auth_service.loginWToken(token).subscribe({
          next:data =>{
            if(data != null){
              this.currentUser = data;
              this.getAllService();
              this.getAllReception();
              this.getAllOperation();
              this.getAllTypeReception();
              this.getAllAgent();
            }else{
              this.router.navigateByUrl('/admin/sessions/signin');
            }
          },
          error:error =>{
              this.errorMessage = error.message;
              console.error("There is an error !", error)
              setTimeout(() => {
                this.loading = false;
                this.toastr.error('There is an error.', 'Error!', {progressBar: true});
              }, 3000);
          }
        })
      }
      else{
        this.currentUser = data;
        this.getAllService();
        this.getAllReception();
        this.getAllOperation();
        this.getAllTypeReception();
        this.getAllAgent();
        console.log("the connected user is "+JSON.stringify(data));
      }
    });
  }

  submit(f) {
    
    if(f.valid){
      this.loading = true; 
      this.loading = true;
      //this.reception.service = JSON.parse(f.value.service)
      this.reception.operation = this.isTypeRecepVisit? null:JSON.parse(f.value?.operation);
      this.reception.typeRecep = JSON.parse(f.value?.typeRecep);
      this.reception.agentVisite = this.isTypeRecepVisit? this.reception.agentVisite:null;
      //this.reception.service = this.reception.operation.service;
      this.reception.company = this.currentUser.company;

      //console.log(JSON.stringify(this.reception));
    
      this.receptionService.insertReception(this.reception).subscribe({
        next:(data:any) =>{
          let alertMessage;
          if(!this.isTypeRecepVisit){
            console.warn(JSON.stringify(data));
            // recuperer le nombre d'element en attente pour l'operation
            this.nombreItem = this.CountReceptionByOperation(this.reception.operation);            
            alertMessage = `Vous êtes dans la file d'attente pour le service ${data.operation.libelle}. \n Vous êtes le ticket Nnumero ${data.ticket_numero}. Actuellement vous le numero ${this.nombreItem+1} dans la file d'attente et
            votre delai d'attente est estimée à 25min.`
          }else{
            alertMessage = `Merci de patienter\n ${this.reception.agentVisite.nom +" "+this.reception.agentVisite.prenom} a été notifié de votre visite`
          }

          let message = {
            contact: data.phone1,
            message: alertMessage
          }

          try {
            //this.sendValidationCode(message);
          } catch (error) {
            
          }

          setTimeout(() => {
            this.loading = false;
            this.toastr.success('Profile updated.', 'Success!', {progressBar: true});
            this.ws_service.sendMessage("ticket ajouter");
            f.reset();
            Swal.fire({
              title: 'Success!',
              text: alertMessage,
              icon: 'success',
              confirmButtonText: 'OK'
            }).then(()=>{
              window.location.reload();
            });
          }, 2000);
        },
        error:error =>{
          this.errorMessage = error.message;
          console.error("There is an error !", error)
          setTimeout(() => {
            this.loading = false;
            this.toastr.error('There is an error.', 'Error!', {progressBar: true});
          }, 3000);
        }
      })
    }
    
  }

  getAllReception(){
    this.receptionService.getAllReception(this.currentUser.company.id).subscribe({
      next:data =>{
        //this.arrayReception = data;
        this.arrayReception = data?.filter((item)=>{
          return item.ticket_top_actif == '1'
        }); 
        this.nombreItem = this.arrayReception.length;
        console.log("Le nombre de personne est :"+ JSON.stringify(this.nombreItem));
      },
      error:error =>{
        console.error("There is an error !", error)
      }
    })
  }

  selectTypeReception(event){
    var typeRecep:TypeReception = JSON.parse(event?.target?.value);
    this.isTypeRecepVisit = typeRecep.libelle === "Rendre Visite"?true:false;
    this.isAgentDisplay = typeRecep.libelle === "Rendre Visite"?true:false;

    if(typeRecep.libelle === "Rendre Visite"){
      this.reception.operation = null;
      this.reception.service = null;
    }else{
      this.reception.agentVisite = null;
    }
  }

  getAllAgent(){
    this.agentService.getAllAgentbyCompany(this.currentUser.company.id).subscribe({
      next:data =>{
        this.arrayAgent = data.filter((item)=>{
          return item.role.role_name != "Administrateur"; // Tous les roles saufs Administrateurs
        })
      },
      error:error =>{
        console.error("There is an error !", error)
      }
    })
  }

  sendValidationCode(message:any){
    this.auth_service.sendValidationText(message).subscribe(
      {
        next:data =>{
          console.warn(JSON.stringify(data));
          setTimeout(() => {
            //this.loading = false;
            this.toastr.success('Profile updated.', 'Success!', {progressBar: true}); //sessions
          }, 1000);
        },
        error:error =>{
          this.errorMessage = error.message;
          console.error("There is an error !", error)
          setTimeout(() => {
            this.loading = false;
            this.toastr.error('There is an error.', 'Error!', {progressBar: true});
          }, 1000);
        }
      }
    )
  }

}
