import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  insert_agent_url = environment.apidomain+"agent/add-agent";
  get_All_agent_url = environment.apidomain+"agent/get-all-agent";
  get_All_agent_by_company_url = environment.apidomain+"agent/get-company-agent";
  get_All_agent_by_company_by_idagent_url = environment.apidomain+"agent/get-company-agent-by-ID";
  get_All_agent_by_company_service_url = environment.apidomain+"agent/get-company-service-agent";

  /**API OPACITY */
  insert_agent_url_2 = environment.pythonapidomain+"agents/create-agent/";
  agent_url_2 = environment.pythonapidomain+"agents/";
  get_All_agent_url_2 = environment.pythonapidomain+"agents/list-agent/";
  get_All_agent_by_company_by_idagent_url_2 = environment.pythonapidomain+"agents/get-company-agent-by-ID/";

  constructor(private http: HttpClient) { }

  insertAgent<Agent2>(agent: Agent2){
    return this.http.post(this.insert_agent_url_2, agent)
  }

  deleteAgent<Agent2>(agent: any):Observable<any>{
    return this.http.delete(this.agent_url_2+agent.id);
  }

  getAllAgent():Observable<any>{
    return this.http.get(this.get_All_agent_url)
  }

  getAllAgentbyCompany(idcompany):Observable<any>{
    return this.http.get(this.get_All_agent_url_2,{
      params:{
        "company":idcompany
      }
    })
  }

  getAllAgentbyCompanyAndIdAgent(idcompany,idAgent):Observable<any>{
    return this.http.get(this.get_All_agent_by_company_by_idagent_url_2,{
      params:{
        "company":idcompany,
        "agent":idAgent
      }
    })
  }

  getAllAgentbyCompanyAndService(idcompany,idService):Observable<any>{
    return this.http.get(this.get_All_agent_by_company_service_url,{
      params:{
        "idcompany":idcompany,
        "idservice":idService
      }
    })
  }
}
