import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Agent2, User, User2 } from '../models/modeles';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private user:Agent2 = new Agent2()
  private _user: ReplaySubject<Agent2> = new ReplaySubject<Agent2>(1);

  private dataSource: BehaviorSubject<Agent2> = new BehaviorSubject<Agent2>(this.user);
  currentUser:Observable<Agent2> = this.dataSource.asObservable();
  
  loading: boolean;
  errorMessage;

  constructor() { }

   /**
     * Setter & getter for user
     *
     * @param value
     */
   set user_(value: Agent2)
   {
       // Store the value
       this._user.next(value);
   }

   get user$(): Observable<Agent2>
   {
       return this._user.asObservable();
   }

  /* update de Admin */
  updateCurrentUser(user: Agent2){
    this.dataSource.next(user);
  }
}
